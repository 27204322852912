import { sharedWorkerActions, sharedWorker } from 'workers/sharedWorker/utils/utils';
import { PROFILE } from 'config';

export const beforeUnloadHandler = () => {
    sharedWorkerActions.removeWindow({ id: sharedWorker.id });
    switch (PROFILE) {
        case 'development':
            document.cookie = 'aiqD=AlertsON; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            break;
        case 'staging':
            document.cookie = 'aiqS=AlertsON; path=/; domain=.birdseyesecurity.com; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            break;
        case 'production':
            document.cookie = 'aiqP=AlertsON; path=/; domain=.birdseyesecurity.com; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            break;
        default:
            document.cookie = 'aiq=AlertsON; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
};
