export const transformData = (data, fieldName) => {
    if (!data) return {};
    return Object.keys(data).reduce((acc, key) => {
        if (key !== fieldName) {
            const newKey = key.split('.').slice(1).join('.');
            acc[newKey] = data[key];
        }
        return acc;
    }, {});
};
