import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import MicrophoneIconButton from 'components/UI/Buttons/MicrophoneIconButton/MicrophoneIconButton';
import { IconButton, Tooltip } from '@mui/material';
import { kurentoManager } from 'services/Kurento/KurentoManager';
import { beforeUnloadHandler } from 'utils/beforeunloadHandler';
import { talkManager } from 'services/Talk/TalkManager';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import * as transactionsSelectors from 'store/transactionsSlice/transactionsSelectors';

function TwoWayAudio({ enableDemoAudioFile, onToggleAudio, camVideoId, camConfig }) {
    const isTalkSessionStarting = useSelector(transactionsSelectors.selectTalkSessionStarting);
    const activeTalkUrl = useSelector(transactionsSelectors.selectActiveTalkUrl);
    const activeDemoTalkUrl = useSelector(transactionsSelectors.selectActiveDemoTalkUrl);
    const isTalkSessionPaused = useSelector(transactionsSelectors.selectTalkSessionPaused);
    const { talkUrl, camId, name, extensionNumber3CX } = camConfig;
    const isTalkUrlActive = talkUrl === activeTalkUrl;
    const isDemoTalkUrlActive = talkUrl === activeDemoTalkUrl;
    const isCameraActive = camConfig?.status !== 'INACTIVE';

    const talkActivationHandler = useCallback(
        async function () {
            if (activeTalkUrl && talkUrl === activeTalkUrl) {
                if (isTalkSessionPaused) {
                    talkManager.toggleOutgoingAudioActive();
                    return;
                }
                await talkManager.stopSession();
            } else {
                onToggleAudio && onToggleAudio({ muteAll: true });
                await talkManager.startSession({ talkUrl }, camId, name);
            }
        },
        [activeTalkUrl, talkUrl, onToggleAudio, isTalkSessionPaused, camId, name]
    );

    const talkActivationDemoFileHandler = useCallback(
        async function () {
            if (activeDemoTalkUrl && talkUrl === activeDemoTalkUrl) await talkManager.stopDemoFile();
            else {
                !kurentoManager.isAudioEnabled(camVideoId) && onToggleAudio();
                await talkManager.playDemoFile(talkUrl, camId);
            }
        },
        [talkUrl, activeDemoTalkUrl, onToggleAudio, camVideoId, camId]
    );

    const start3CXHandler = () => {
        //beforeunload event is triggered on open (with _self) removing event listeners to avoid streams stop and token removal
        eventListeners(false);
        window.open(`tel:${extensionNumber3CX.padEnd(6, '-')}`, '_self'); //useing _self instead of _blank to avoid flickering
        eventListeners(true);
    };
    return (
        <>
            {extensionNumber3CX && isCameraActive && (
                <Tooltip title="Start 3CX extension">
                    <IconButton
                        sx={{
                            color: 'white',
                            padding: 0,

                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        }}
                        onClick={start3CXHandler}
                        size="small"
                    >
                        <MicIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            )}

            {talkUrl && isCameraActive && (
                <>
                    {enableDemoAudioFile && (
                        <IconButton
                            onClick={talkActivationDemoFileHandler}
                            disabled={!!activeTalkUrl || (!!activeDemoTalkUrl && !isDemoTalkUrlActive)}
                            sx={{
                                color: 'white',
                                padding: 0,

                                '&:hover': {
                                    backgroundColor: 'transparent',
                                },
                            }}
                        >
                            {!isDemoTalkUrlActive && (
                                <Tooltip title="Play pre-recorded audio file">
                                    <PlayArrowIcon fontSize="small" />
                                </Tooltip>
                            )}
                            {isDemoTalkUrlActive && (
                                <Tooltip title="Stop pre-recorded audio file">
                                    <StopIcon fontSize="small" color="error" />
                                </Tooltip>
                            )}
                        </IconButton>
                    )}

                    <MicrophoneIconButton
                        fontSize="small"
                        containerStyles={{
                            color: 'white',
                            padding: 0,

                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        }}
                        iconProps={{ fontSize: 'small' }}
                        active={isTalkUrlActive}
                        starting={isTalkUrlActive && isTalkSessionStarting}
                        paused={isTalkUrlActive && isTalkSessionPaused}
                        disabled={!isTalkUrlActive && isTalkSessionStarting}
                        onClick={talkActivationHandler}
                        cameraId={camId}
                        showTooltip
                    />
                </>
            )}
        </>
    );
}

export default TwoWayAudio;

const eventListeners = function (set) {
    if (set) {
        kurentoManager.setBeforeUnloadListener();
        window.addEventListener('beforeunload', beforeUnloadHandler);
    } else {
        window.removeEventListener('beforeunload', beforeUnloadHandler);
        kurentoManager.removeBeforeUnloadListener();
    }
};
