import styles from './Dropdown.module.css';
import Options from './Options/Options';
import { useRef, useEffect } from 'react';

function Dropdown({ dropdownOpened, noOptionsText, isOptionsLoadingInProgress, optionsLoadingText, ...props }) {
    const { filteredOptions, freeSolo } = props;
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleMouseDown = (event) => {
            if (dropdownRef.current?.contains(event.target)) {
                event.preventDefault();
                event.stopPropagation();
            }
        };

        document.addEventListener('mousedown', handleMouseDown);
        return () => {
            document.removeEventListener('mousedown', handleMouseDown);
        };
    }, []);

    return (
        <div className={styles['dropdown']} ref={dropdownRef}>
            {dropdownOpened && !!filteredOptions.length && <Options {...props} />}
            {dropdownOpened && !filteredOptions.length && !freeSolo && (
                <p className={styles['no-option']}>{isOptionsLoadingInProgress ? optionsLoadingText : noOptionsText}</p>
            )}
        </div>
    );
}

export default Dropdown;
