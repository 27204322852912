import { createSelector } from '@reduxjs/toolkit';
import { TRANSACTION_PAGE_TABS } from 'config';
import { kurentoManager } from 'services/Kurento/KurentoManager';
import { selectUser } from 'store/authSlice/authSelectors';
const {
    VIDEO_WALL_TAB: { ID: videoWallTabId, DUMMY_TRANSACTION_ID: dummyTransactionId },
    REOPENED_TRANSACTION_TAB: { ID: reopenedTransactionTabId },
} = TRANSACTION_PAGE_TABS;

const selectTransactionsSlice = (state) => state.transactions;

export const selectActiveTab = createSelector([selectTransactionsSlice], (transactiosSlice) => transactiosSlice.activeTab);
export const activeTabHasStreams = createSelector([selectTransactionsSlice, selectActiveTab], (transaactionSlice, activeTab) => {
    if (typeof activeTab === 'number') return true; //meaninng transaction tab is active
    if (activeTab === reopenedTransactionTabId) return true;
    if (activeTab === videoWallTabId) return !!transaactionSlice.videoWallCameras?.level1?.length;
    return false;
});

export const selectTabsAudioConfig = createSelector([selectTransactionsSlice], (transactionSlice) => transactionSlice.tabsAudio);

export const selectActiveTalkUrl = createSelector([selectTransactionsSlice], (transactionSlice) => transactionSlice.activeTalkUrl);

export const selectTalkSessionStarting = createSelector([selectTransactionsSlice], (transactionSlice) => transactionSlice.talkSessionStarting);

export const selectActiveDemoTalkUrl = createSelector([selectTransactionsSlice], (transactionSlice) => transactionSlice.activeDemoTalkUrl);
export const selectTalkSessionPaused = createSelector([selectTransactionsSlice], (transactionSlice) => transactionSlice.talkSessionPaused);
export const selectSharedWorker = createSelector([selectTransactionsSlice], (transactionSlice) => transactionSlice.sharedWorker);

export const selectTabAudioConfigByTabId = (tabId) =>
    createSelector([selectTabsAudioConfig, (_, id = tabId) => id], (tabAudioOffObj, id) => tabAudioOffObj?.[id]);

export const selectIsStompBrokerConnected = createSelector([selectTransactionsSlice], (transactiosSlice) => transactiosSlice.isStompBrokerConnected);

export const selectIsStompBrokerConnectionInProgress = createSelector(
    [selectTransactionsSlice],
    (transactiosSlice) => transactiosSlice.isStompBrokerConnectionInProgress
);

export const selectHasStompBrokerError = createSelector([selectTransactionsSlice], (transactiosSlice) => transactiosSlice.hasStompBrokerError);

export const selectTransactions = createSelector([selectTransactionsSlice], (transactiosSlice) => transactiosSlice.transactions);

export const selectChangingOperationalModeInProgress = createSelector(
    [selectTransactionsSlice],
    (transactionSlice) => transactionSlice.changingOperationalModeInProgress
);
export const selectIsReceivingTransactionsEnabled = createSelector(
    [selectTransactionsSlice],
    (transactionSlice) => transactionSlice.isReceivingTransactionsEnabled
);

export const selectIsVideoWallEnabled = createSelector([selectTransactionsSlice], (transactionSlice) => transactionSlice.isVideoWallEnabled);
export const selectVideoWallCameras = createSelector([selectTransactionsSlice], (transactiosSlice) => transactiosSlice.videoWallCameras);
export const selectDummyVideoWallTransaction = createSelector([selectVideoWallCameras], (camsConfig) => {
    return {
        tabId: videoWallTabId,
        id: dummyTransactionId,
        camsConfig,
    };
});
export const selectFetchingVideoWallCamerasInProgress = createSelector(
    [selectTransactionsSlice],
    (transactiosSlice) => transactiosSlice.fetchingVideoWallCamerasInProgress
);

export const selectUpdatingTransactionInProgress = createSelector(
    [selectTransactionsSlice],
    (transactiosSlice) => transactiosSlice.updatingTransactionInProgress
);
export const selectHasReopenedTransaction = createSelector([selectTransactionsSlice], (transactiosSlice) => transactiosSlice.hasReopenedTransaction);
export const selectLastClosedTransasctionId = createSelector(
    [selectTransactionsSlice],
    (transactionSlice) => transactionSlice.lastClosedTransactionId
);

export const selectReopenTransaction = createSelector([selectTransactionsSlice], (transactionsSlice) => transactionsSlice.reopenTransaction);

export const selectCheckingAiqServerHealthStatusInProgress = createSelector(
    [selectTransactionsSlice],
    (transactiosSlice) => transactiosSlice.checkingAiqServerHealthInProgress
);

export const selectAiqServerHealthStatus = createSelector([selectTransactionsSlice], (transactiosSlice) => transactiosSlice.aiqServerHealthStatus);

export const selectCheckingAiqServerHealthError = createSelector(
    [selectTransactionsSlice],
    (transactiosSlice) => transactiosSlice.checkingAiqServerHealthError
);

export const selectActiveTransactionId = createSelector([selectTransactions, selectActiveTab], (transactions, activeTab) => {
    let transactionId = '';
    for (let transaction of transactions) {
        if (transaction.tabId === activeTab) {
            transactionId = transaction.id;
        }
    }

    return transactionId;
});

export const selectTranasctionById = (id) =>
    createSelector([selectTransactions, (_, transactionId = id) => transactionId], (allTransactions, transactionId) => {
        return allTransactions.find((transaction) => {
            return transaction.id === transactionId;
        });
    });

export const selectRequestFullScreen = createSelector([selectTransactionsSlice], (transactionSlice) => transactionSlice.requestFullScreenForTabId);

export const selectLocationIdByTransactionId = (id) =>
    createSelector([selectTranasctionById(id)], (transaction) => {
        return transaction.locationId;
    });
export const selectTransactionCamerasByTransactionId = (id) => {
    let selectorFn;
    const isVideoWall = id === TRANSACTION_PAGE_TABS.VIDEO_WALL_TAB.DUMMY_TRANSACTION_ID;
    if (!isVideoWall) selectorFn = selectTranasctionById(id);
    if (isVideoWall) selectorFn = selectVideoWallCameras;

    return createSelector([selectorFn, selectUser], (object, { username }) => {
        const level1 = (isVideoWall ? object.level1 : object.camsConfig.level1) || [];
        const level2 = (isVideoWall ? object.level2 : object.camsConfig.level2) || [];
        const configL1 = level1.reduce((configL1, camConfig) => {
            kurentoManager.create(username, camConfig);

            if (!camConfig.hidden) !configL1.find((cam) => cam.camId === camConfig.camId) && configL1.push(camConfig);
            else kurentoManager.removeVideoOutput(kurentoManager.getVideoElementId('wall', camConfig.id), false);

            return configL1;
        }, []);

        const configL2 = level2.reduce((configL1, camConfig) => {
            kurentoManager.create(username, camConfig);
            if (!configL1.find((cam) => cam.camId === camConfig.camId)) configL1.push(camConfig);
            return configL1;
        }, []);
        return { configL1, configL2 };
    });
};

export const reportIssuesModalState = createSelector([selectTransactionsSlice], (transactionSlice) => transactionSlice.reportIssuesModalState);
export const selectedCameraIssues = createSelector([selectTransactionsSlice], (transactionSlice) => transactionSlice.selectedCameraIssues);
export const selectedIssueUrl = createSelector([selectTransactionsSlice], (transactionSlice) => transactionSlice.selectedIssueUrl);
export const issueTitle = createSelector([selectTransactionsSlice], (transactionSlice) => transactionSlice.issueTitle);
export const issueDataLoggs = createSelector([selectTransactionsSlice], (transactionSlice) => transactionSlice.issueDataLoggs);

export const selectVideoWallAlisaTransactionDetection = createSelector(
    [selectTransactionsSlice],
    (transactiosSlice) => transactiosSlice.videoWallAlisaTransactionDetection
);

export const transactionDetectionAgentInformation = createSelector(
    [selectTransactionsSlice],
    (transactiosSlice) => transactiosSlice.transactionDetectionAgentInformation
);
