import { INVENTORY_PROPERTIES } from 'utils/constants';

export const buildLookupInventory = ({ inventoryData, transactionFormConfig }) => {
    return transactionFormConfig.fields.map((field) => {
        if (INVENTORY_PROPERTIES.includes(field.dataField)) {
            // build lookup dataSource
            const dataSource = inventoryData.reduce((acc, item) => {
                const { data } = item;
                if (field.dataField in data && data[field.dataField] !== undefined) {
                    const newItem = {
                        ...item,
                        [field.dataField]: data[field.dataField],
                    };
                    acc.push(newItem);
                }
                return acc;
            }, []);

            return {
                ...field,
                lookup: {
                    dataSource,
                    valueExpr: field.dataField,
                    displayExpr: field.dataField,
                    referenceExpr: field.dataField,
                },
                referenceField: field.dataField,
            };
        }
        return { ...field }; // Keep field unchanged if not in INVENTORY_PROPERTIES
    });
};
