import { axiosAiq } from 'config';

export const fetchInventory = async (locationId) => {
    if (!locationId) {
        return [];
    }
    const response = await axiosAiq.get(`/inventory-source-data/location/${locationId}`);
    const { data } = response;
    return data;
};

export const inventoryValidation = async (transactionData) => {
    const response = await axiosAiq.post('/inventory-item-types/from-truck-transaction', transactionData);
    const { data } = response;
    return data;
};
