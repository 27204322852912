export const PERIOD_ACCESS_TYPES = {
    ONE_TIME: 'One Time',
    PERMANENT: 'Ongoing',
};

export const INDUSTRY_TYPE = {
    Automotive: 'AUTOMOTIVE',
    Trucking: 'TRUCKING',
    Residental: 'RESIDENTAL',
};

export const INDUSTRY_TYPE_DROPDOWN_OPTION = {
    [INDUSTRY_TYPE.Automotive]: {
        id: INDUSTRY_TYPE.Automotive,
        label: 'Automotive',
    },
    [INDUSTRY_TYPE.Trucking]: {
        id: INDUSTRY_TYPE.Trucking,
        label: 'Trucking',
    },
    [INDUSTRY_TYPE.Residental]: {
        id: INDUSTRY_TYPE.Residental,
        label: 'Residental',
    },
};

export const GATE_IN = 'IN';
export const GATE_OUT = 'OUT';

export const VEHICLE_PROPERTIES = ['vehicle.unitNumber', 'vehicle.licensePlateNumber'];
export const TRAILER_PROPERTIES = [
    'trailer.trailerNumber',
    'trailer.containerNumber',
    'trailer.chassisNumber',
    'trailer.dollyNumber',
    'trailer.equipmentNumber',
];

export const INVENTORY_PROPERTIES = VEHICLE_PROPERTIES.concat(TRAILER_PROPERTIES);

export const INVENTORY_STATUS = {
    GOOD_TO_GO: 'GOOD_TO_GO',
    TERMINATED: 'TERMINATED',
};

export const INVENTORY_STATUS_LABEL = {
    GOOD_TO_GO: 'Good to Go',
    TERMINATED: 'Terminated',
    IN_SHOP: 'In Shop',
    GROUNDED: 'Grounded',
    IN_LEASE: 'In Lease',
    IN_SALE_TRADE: 'In Sale / Trade',
    ANNUAL_INSPECT: 'Annual Inspect',
    WASH: 'Wash',
    UNAVAILABLE: 'Unavailable',
    BREAKDOWN: 'Breakdown',
};

export const RELATION_TYPE = {
    Tenant: 1,
    ThirdPartyCarrier: 2,
    Vendor: 3,
    Unknown: 4,
};

export const RELATION_TYPE_DROPDOWN_OPTION = {
    [RELATION_TYPE.Tenant]: {
        id: RELATION_TYPE.Tenant,
        label: 'Tenant',
    },
    [RELATION_TYPE.ThirdPartyCarrier]: {
        id: RELATION_TYPE.ThirdPartyCarrier,
        label: 'Third Party Carrier',
    },
    [RELATION_TYPE.Vendor]: {
        id: RELATION_TYPE.Vendor,
        label: 'Vendor',
    },
    [RELATION_TYPE.Unknown]: {
        id: RELATION_TYPE.Unknown,
        label: '',
    },
};

export const CAMERA = {
    STATUS_ACTIVE: 'ACTIVE',
    AUDIO_TYPE: {
        HIKVISION_2WA: 'HIKVISION_2WA',
        EXTENSION_3CX: 'EXTENSION_3CX',
    },
};
export const USER_ROLES = {
    ADMIN: 'admin',
    TEAM_LEADER: 'team_leader',
};
