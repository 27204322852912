import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import PropTypes from 'prop-types';

const CustomTooltip = ({ backgroundColor, children, ...props }) => {
    const StyledTooltip = styled(({ className, ...restProps }) => (
        <Tooltip {...restProps} arrow classes={{ popper: className }}>
            {children}
        </Tooltip>
    ))(() => ({
        [`& .${tooltipClasses.arrow}`]: { color: backgroundColor },
        [`& .${tooltipClasses.tooltip}`]: { backgroundColor },
    }));

    return <StyledTooltip {...props} />;
};

CustomTooltip.propTypes = {
    backgroundColor: PropTypes.string,
    children: PropTypes.node,
};

export default CustomTooltip;
