import { createSlice } from '@reduxjs/toolkit';
import { readFromObj } from 'utils/utils';
const initialState = {
    fetchTransactionFormConfigInProgress: false,
    fetchTransactionFormConfigError: null,

    initializingFormInProgress: false,
    initializingFormError: null,

    submitFormInProgress: false,
    submitFormError: null,

    discardTransactionInProgress: false,
    discardTransactionError: null,

    inventoryMapping: {
        formId: null,
        inProgress: false,
        data: [],
        hasInventoryItemTypeConfiguration: false,
        touched: false,
    },
    inventoryData: [],

    forms: {},
};

const formControlSlice = createSlice({
    name: 'formControl',
    initialState,
    reducers: {
        initializeNewTransactionFormStart(state) {
            state.initializingFormInProgress = true;
            state.initializingFormError = null;
        },
        initializeNewTransactionFormSuccess(state, { payload: { transactionData } }) {
            state.initializingFormInProgress = false;
            state.forms[transactionData.id] = {
                transactionData,
                transactionIdAnnouncementUsed: 0,
                internalIdAnnouncementUsed: '',
                activeSelectionTableName: '',
                form: {
                    values: {},
                    errors: {},
                    touched: {},
                },
            };
        },
        initializeNewTransactionFormFailed(state, { paylodad: err }) {
            state.initializingFormInProgress = false;
            state.initializingFormError = err;
        },

        initializeNewForm(state, { payload: formId }) {
            state.forms[formId] = {
                form: {
                    values: {},
                    errors: {},
                    touched: {},
                },
            };
        },
        removeForm(state, { payload: formId }) {
            // eslint-disable-next-line no-unused-vars
            const { [formId]: toBeDeleted, ...rest } = state.forms;
            state.forms = rest;
        },

        registerNewFormField(state, { payload: { formId, fieldName, initialValue, disabled, required } }) {
            if (disabled) return;

            const form = state.forms[formId].form;

            form.values[fieldName] = initialValue;
            if (!required) return;
            form.errors[fieldName] = true;
            form.touched[fieldName] = false;
        },

        setFieldValue(state, { payload: { formId, fieldName, value } }) {
            const form = state.forms[formId].form;
            form.values[fieldName] = value;
        },

        setFieldTouched(state, { payload: { formId, fieldName } }) {
            const form = state.forms[formId].form;
            form.touched[fieldName] = true;
        },

        setFieldError(state, { payload: { formId, fieldName, error = true } }) {
            const form = state.forms[formId].form;
            form.errors[fieldName] = error;
        },

        submitFormStart(state, { payload: { transactionId } }) {
            state.submitFormInProgress = transactionId;
            state.submitFormError = null;
        },
        submitFormSuccess(state, { payload }) {
            state.submitFormInProgress = false;
            state.forms[payload.id].transactionData = payload;
        },
        submitFormFailed(state, { payload: error }) {
            state.submitFormInProgress = false;
            if (error) state.submitFormError = error;
        },

        setSubmitFormInProgress(state, { payload: submitFormInProgress }) {
            state.submitFormInProgress = submitFormInProgress;
        },

        discardTransactionStart(state, { payload: { transactionId } }) {
            state.discardTransactionInProgress = transactionId;
            state.discardTransactionError = null;
        },
        discardTransactionSuccess(state) {
            state.discardTransactionInProgress = false;
        },
        discardTransactionFailed(state, { payload: error }) {
            state.discardTransactionInProgress = false;
            if (error) state.discardTransactionError = error;
        },

        setdiscardTransactionInProgress(state, { payload: discardTransactionInProgress }) {
            state.discardTransactionInProgress = discardTransactionInProgress;
        },

        setTransactionData(state, { payload: { transactionData, setNewAutomation = false } }) {
            const formId = transactionData.id;
            const formObj = state.forms?.[formId];
            if (!formObj) return;
            formObj.transactionData = transactionData;
            setNewAutomation && (formObj.form = { ...formObj.form, automation: readAutomation(formObj) });
        },
        setRelaysConfig(state, { payload: { transactionId, relaysConfig } }) {
            const formObj = state.forms?.[transactionId];
            if (!formObj) return;
            formObj.relaysConfig = relaysConfig;
        },

        updateTransactionDataAutomation(state, { payload: { automation } }) {
            const formId = automation.id;
            const formObj = state.forms?.[formId];
            if (!formObj) return;
            formObj.transactionData.version = automation.version;
            formObj.transactionData.automation = automation;
            formObj.form = { ...formObj.form, automation: readAutomation(formObj) };
        },

        setEncodedImages(state, { payload: { encodedImages, transactionId } }) {
            const formObj = state.forms?.[transactionId];
            if (!formObj) return;
            const imagesToSet = encodedImages.map((image) => {
                const { id, version } = image;
                return { id, version, imageCategory: image['image_category'], encodedImage: image['encoded_image'] };
            });

            formObj.form.encodedImages = imagesToSet;
        },

        saveCameraStreamSnapshot(state, { payload: { snapshot, transactionId, imageCategory, id } }) {
            const formObj = state.forms?.[transactionId];
            if (!formObj) return;
            if (!formObj.form.cameraSnapshots) formObj.form.cameraSnapshots = [];
            formObj.form.cameraSnapshots.push({ id, imageCategory, encodedImage: snapshot });
        },

        setAutomationValues(state, { payload: { automationObjTemplate, transactionId, newAutomationValues, customSource, setAlisaAutomation } }) {
            const formObj = state.forms[transactionId];
            if (setAlisaAutomation) {
                formObj.form.automation = {};
                formObj.form = { ...formObj.form, automation: readAutomation(formObj) };
            } else formObj.form.automation = newAutomationValues ?? createAutomationValues(formObj, automationObjTemplate, customSource);
        },

        setAnnouncementIdUsed(state, { payload: { formId, manualAnnouncementIdUsed, internalIdAnnouncementUsed } }) {
            const formObj = state.forms?.[formId];
            formObj.transactionIdAnnouncementUsed = manualAnnouncementIdUsed || 0;
            formObj.internalIdAnnouncementUsed = internalIdAnnouncementUsed || '';
        },

        resetTransactionFormState(state, { payload: transactionId }) {
            state.forms[transactionId] = {
                ...state.forms[transactionId],
                form: {
                    values: {},
                    errors: {},
                    touched: {},
                },
            };
        },

        fetchTransactionFormConfigStart(state) {
            state.fetchTransactionFormConfigInProgress = true;
            state.fetchTransactionFormConfigError = null;
        },

        fetchTransactionFormConfigSuccess(state, { payload: { transactionId, transactionFormConfig } }) {
            const formObj = state.forms[transactionId];
            state.fetchTransactionFormConfigInProgress = false;

            transactionFormConfig.fields.forEach((field) => {
                //TODO solve this on BE
                if (field.dataType === 'boolean') {
                    field.lookup = {
                        valueExpr: 'value',
                        displayExpr: 'label',
                        dataSource: [
                            { value: true, label: 'Yes' },
                            { value: false, label: 'No' },
                        ],
                    };
                }
            });

            if (formObj) {
                formObj.formConfig = {
                    ...transactionFormConfig,
                };

                formObj.form = {
                    ...formObj.form,
                    automation: readAutomation(formObj),
                };
            }
        },
        fetchTransactionFormConfigFailed(state, { payload: error }) {
            state.fetchTransactionFormConfigInProgress = false;
            state.fetchTransactionFormConfigError = error;
        },

        setFormsObj(state, { payload: newFormsObject }) {
            state.forms = newFormsObject;
        },
        setActiveSelectionTableName(state, { payload: { formId, tableName = '' } }) {
            const formObj = state.forms?.[formId];
            formObj.activeSelectionTableName = tableName;
        },

        setInventoryData(state, { payload: inventoryData }) {
            state.inventoryData = inventoryData;
        },
        fetchInventoryMappingStart(state) {
            state.inventoryMapping.inProgress = true;
            state.inventoryMapping.data = [];
        },
        fetchInventoryMappingFailed(state) {
            state.inventoryMapping.inProgress = false;
            state.inventoryMapping.data = [];
        },
        setInventoryDataMapping(state, { payload: { inventoryMapping, formId } }) {
            state.inventoryMapping.formId = formId;
            state.inventoryMapping.data = inventoryMapping?.data || [];
            state.inventoryMapping.hasInventoryItemTypeConfiguration = inventoryMapping?.hasInventoryItemTypeConfiguration || false;
            state.inventoryMapping.inProgress = false;
            state.inventoryMapping.touched = true;
        },

        validateTransactionFormField() {},
        validateTransactionWithInventoryData() {},
        activateRelay() {},
        copyAppointmentData() {},
        copyBisonAnnouncement() {},
        copyLocationTransaction() {},
    },
});

export const formControlActions = formControlSlice.actions;
export const formControlReducer = formControlSlice.reducer;

// pure helper functions
function readAutomation(formObj) {
    const {
        transactionData: { automation },
        formConfig: { fields },
        form: { automation: currentAutomation },
    } = formObj;

    if (!automation || !fields || typeof automation !== 'object' || typeof fields !== 'object') return {};

    return fields.reduce((newAutomationValues, { dataField: fieldName, lookup }) => {
        let fieldAutomation = readFromObj(automation, fieldName);

        if (fieldAutomation === 'aiq.fieldNotExist') return newAutomationValues;
        if (currentAutomation?.[fieldName]?.source && currentAutomation?.[fieldName]?.source !== 'alisa') return newAutomationValues;

        const sourceValue = lookup.dataSource
            ? lookup.displayExpr
                ? lookup.dataSource.find((option) => option[lookup.valueExpr] === fieldAutomation?.value)?.[lookup.displayExpr]
                : fieldAutomation.value
            : fieldAutomation.value;

        if (fieldAutomation?.source && sourceValue !== '-1' && +fieldAutomation?.conf >= 0)
            newAutomationValues[fieldName] = {
                source: fieldAutomation?.source,
                sourceValue,
                info: `${(+fieldAutomation?.conf * 100).toFixed(0)}%`,
            };

        return newAutomationValues;
    }, currentAutomation || {});
}

function createAutomationValues(formObj, automationObjTemplate, customSource = {}) {
    const {
        formConfig: { fields },
        form: { automation },
        transactionData,
    } = formObj;

    const { values, fieldMappings } = customSource;

    return fields.reduce((newAutomations, { dataField: fieldName, lookup, editable }) => {
        if (!editable || fieldName === 'sendToBison') return newAutomations; //TODO do not harcode ask for flags from BE

        const fieldCurrentAutomation = !!automation && automation[fieldName];
        const source = values || transactionData;
        const dataFieldToRead = fieldMappings ? fieldMappings[fieldName] : fieldName;

        if (!dataFieldToRead) return newAutomations;

        const sourceValue = getSourceValue(source, dataFieldToRead, lookup);

        if (!sourceValue) {
            if (fieldCurrentAutomation && fieldCurrentAutomation.source === 'alisa') newAutomations[fieldName] = fieldCurrentAutomation;
            return newAutomations;
        }

        newAutomations[fieldName] = { sourceValue, ...automationObjTemplate };

        return newAutomations;
    }, {});
}

function getSourceValue(source, fieldName, lookup) {
    let fieldValue = readFromObj(source, fieldName);

    if (!fieldValue || fieldValue === 'aiq.fieldNotExist' || !lookup?.dataSource) return null;

    return lookup.dataSource
        ? lookup.dataSource.find((option) => {
              const findExpr = lookup.valueExpr || lookup.displayExpr;
              const valueToCompare = typeof fieldValue === 'object' ? fieldValue[findExpr] : fieldValue;
              return option[findExpr] === valueToCompare;
          })?.[lookup.displayExpr] || fieldValue
        : fieldValue;
}
