import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import styles from './Option.module.css';
import { transformData } from './helper';

const VehicleOption = ({ data, fieldName }) => {
    const transformedData = transformData(data, fieldName);
    const { unitNumber, licensePlateNumber } = transformedData;

    return (
        <Stack spacing={0.5}>
            {unitNumber && (
                <Typography fontSize="0.8rem" fontWeight={400}>
                    <span className={styles.title}>Unit #:</span>
                    <span className={styles.value}>{unitNumber}</span>
                </Typography>
            )}
            {licensePlateNumber && (
                <Typography fontSize="0.8rem" fontWeight={400}>
                    <span className={styles.title}>License Plate #:</span>
                    <span className={styles.value}>{licensePlateNumber}</span>
                </Typography>
            )}
        </Stack>
    );
};

VehicleOption.propTypes = {
    data: PropTypes.object,
    fieldName: PropTypes.string,
};

export default VehicleOption;
