import { Button } from '@mui/material';
import styles from './RampActions.module.css';
import { useDispatch } from 'react-redux';
import { formControlActions } from 'store/formControlSlice/formControlSlice';

function RampActions({ config, classes }) {
    const dispatch = useDispatch();
    const { name, relaySlotId, signalLabels } = config || {};

    const rampActions = signalLabels.map((el, index) => {
        let operation = el.operation;
        return (
            <Button
                variant="outlined"
                size="large"
                color="primary"
                key={`${operation}_${index}`}
                onClick={() => dispatch(formControlActions.activateRelay({ relaySlotId, operation }))}
            >
                {el.label}
            </Button>
        );
    });

    return (
        <div className={`${styles['ramp']} ${classes || ''}`}>
            <div className={styles['ramp__header']}>
                <p>{name}</p>
            </div>
            <div className={styles['ramp__actions']}>{rampActions}</div>
        </div>
    );
}

export default RampActions;

// async function activateRelay(relayId, operation) {
//     try {
//         console.log(`activateRelay(): ACTIVATING operation=${operation} on relayId=${relayId}`);
//         await axiosAiq.post(`/relay/submit/${relayId}?operation=${operation}`);
//         console.log(`activateRelay(): SUCCESSFUL operation=${operation} on relayId=${relayId}`);

//         showNotification({ title: 'SUUCCESS', variant: 'success' });
//     } catch (err) {
//         console.log(`activateRelay(): ERROR activating operation=${operation} on relayId=${relayId}`);
//         const title = `Error activating operation=${operation} on relayId=${relayId} `;
//         showNotification({ err, title });
//     }
// }
