const IGNORED_COLUMNS = 'appointment.status';
import { fromIsoString } from 'utils/dateTimeUtils';

export function groupedColumn(columns) {
    if (!Array.isArray(columns)) {
        throw new Error('Invalid input: columns should be an array');
    }

    const groupedColumn = {};

    columns.forEach((item) => {
        const { header = '', caption = '', dataField = '', dataType = '', editable = false, format = '', automationField = '' } = item;
        // Skip items with caption "Appointment Status"
        if (dataField === IGNORED_COLUMNS) return;

        if (!groupedColumn[header]) {
            groupedColumn[header] = {
                caption: header,
                columns: [],
            };
        }

        const adjustedDataType = dataField === 'appointment.plannedTime' ? 'date' : dataType;
        groupedColumn[header].columns.push({
            caption,
            automationField,
            dataField,
            dataType: adjustedDataType,
            editable,
            ...(format && { format: 'dd/MM/yyyy. HH:mm' }),
        });
    });
    return Object.values(groupedColumn);
}

export function transformData(data) {
    if (!data || typeof data !== 'object') {
        return {};
    }
    const { fieldValues, ...rest } = data;
    return {
        ...rest,
        ...fieldValues,
        // Date in moment.js is non-serializable, so we need to convert it to string before pass it to redux action
        appointment: {
            ...rest.appointment,
            plannedTime: rest.appointment.plannedTime?.toISOString(),
        },
    };
}

export const mapAppointmentList = (data = []) => {
    const transformData = data.map((item) => {
        const groupedFieldValues = Object.entries(item.fieldValues || {}).reduce((acc, [key, value]) => {
            const [group, field] = key.split('.');

            if (group && field) {
                if (!acc[group]) {
                    acc[group] = {};
                }
                acc[group][field] = value;
            } else {
                acc[key] = value;
            }

            return acc;
        }, {});

        return {
            ...groupedFieldValues,
            id: item.id,
            appointment: {
                number: item.number,
                plannedTime: fromIsoString(item.plannedTime),
            },
            gateDirection: item.gateDirection,
            person: {
                fullName: `${item.personClientPersonnel?.firstName ?? ''} ${item.personClientPersonnel?.lastName ?? ''}`,
                companyName: item.personCompany?.fullName,
                companyId: item.personCompany?.id,
                clientPersonnelId: item.personClientPersonnel?.id,
            },
        };
    });
    return transformData;
};

export const deepMerge = (obj1, obj2) => {
    if (!obj2 || typeof obj2 !== 'object') return obj1;
    if (!obj1 || typeof obj1 !== 'object') return obj2;

    const result = { ...obj1 };

    Object.keys(obj2).forEach((key) => {
        const value = obj2[key];

        if (value && typeof value === 'object' && !Array.isArray(value)) {
            result[key] = deepMerge(result[key] || {}, value);
        } else {
            result[key] = value;
        }
    });

    return result;
};
