import CustomTooltip from 'components/UI/Tooltip/CustomTooltip';
import PropTypes from 'prop-types';
import { INVENTORY_STATUS, INVENTORY_STATUS_LABEL } from 'utils/constants';
import styles from './AIQInput.module.css';

const InventoryStatus = ({ option }) => {
    const inventoryStatus = option?.inventoryMappings?.[0]?.inventoryStatus || '';
    const statusStyles = inventoryStatus === INVENTORY_STATUS.GOOD_TO_GO ? 'good-to-go' : 'terminated';
    const tooltipTitle = INVENTORY_STATUS_LABEL[inventoryStatus];
    const backgroundColor = inventoryStatus === INVENTORY_STATUS.GOOD_TO_GO ? '#AEE9C1' : '#ff8787';

    return (
        <CustomTooltip title={tooltipTitle} placement="top" backgroundColor={backgroundColor}>
            <div className={`${styles['inventory-status']} ${styles[statusStyles]}`} />
        </CustomTooltip>
    );
};

InventoryStatus.propTypes = {
    option: PropTypes.object,
};

export default InventoryStatus;
