import { createSlice } from '@reduxjs/toolkit';
import { audioCameraTypes } from 'config';
import * as aiqConfig from 'config';
import { kurentoManager } from 'services/Kurento/KurentoManager';
import { transformCameraConfiguration } from 'utils/utils';

const {
    DIVISION_TAB: { ID: divisionTabId },
    REOPENED_TRANSACTION_TAB: { ID: reopenedTransactionTabId },
    VIDEO_WALL_TAB: { ID: videoWallTabId },
} = aiqConfig.TRANSACTION_PAGE_TABS;

const modeConfig = {
    videoWall: {
        sessionStorageKey: aiqConfig.sessionStorageKeys.videoWallEnabled,
        defaultState: aiqConfig.transactionPageDefaultMode.videoWallEnabled,
    },
    receivingTransactions: {
        sessionStorageKey: aiqConfig.sessionStorageKeys.receivingTransactionsEnabled,
        defaultState: aiqConfig.transactionPageDefaultMode.receivingTransactionsEnabled,
    },
};

function isModeEnabled(mode) {
    const sessionConfig = sessionStorage.getItem(modeConfig[mode].sessionStorageKey);
    return sessionConfig ? JSON.parse(sessionConfig) : modeConfig[mode].defaultState;
}

const initialState = {
    //checking AIQ Server Health
    checkingAiqServerHealthInProgress: false,
    aiqServerHealthStatus: 'down',
    checkingAiqServerHealthError: null,

    //stomp broker connection
    isStompBrokerConnected: true,
    isStompBrokerConnectionInProgress: false,
    hasStompBrokerError: false,

    //transactions
    transactions: [],
    hasReopenedTransaction: false,
    lastClosedTransactionId: 0,
    updatingTransactionInProgress: {},
    reopenTransaction: {},

    //enabling/disabling transaction/videoWallMode on transactionpage
    changingOperationalModeInProgress: false,
    isReceivingTransactionsEnabled: isModeEnabled('receivingTransactions'),
    isVideoWallEnabled: isModeEnabled('videoWall'),
    //video wall
    videoWallCameras: {},
    fetchingVideoWallCamerasInProgress: true,
    videoWallAlisaTransactionDetection: [],
    transactionDetectionAgentInformation: {},

    //presentational
    newTransactionTabId: 1,
    activeTab: 0,

    //videoStreams
    requestFullScreenForTabId: 0,

    //audio
    tabsAudio: {},
    activeTalkUrl: '',
    activeDemoTalkUrl: '',
    talkSessionPaused: false,
    talkSessionStarting: false,

    //sharedWorker
    sharedWorker: null,

    //report issues
    reportIssuesModalState: false,
    selectedCameraIssues: [],
    selectedIssueUrl: null,
    issueTitle: null,
    issueDataLoggs: null,
};

const transactionsSlice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        stompBrokerConnectionStart(state) {
            state.isStompBrokerConnectionInProgress = true;
            state.isStompBrokerConnected = false;
            state.hasStompBrokerError = false;
        },
        stompBrokerConnectionSuccess(state) {
            state.isStompBrokerConnectionInProgress = false;
            state.isStompBrokerConnected = true;
        },

        setStompBrokerError(state) {
            state.isStompBrokerConnected = false;
            state.isStompBrokerConnectionInProgress = false;
            state.hasStompBrokerError = true;
        },

        setIsStompBrokerConnected(state, { payload }) {
            state.isStompBrokerConnected = payload;
        },
        unsubscribeFromStompBrokerTopic() {},
        subscribeToStompBrokerTopic() {},

        disconnectStompBroker(state) {
            state.isStompBrokerConnected = false;
            state.isStompBrokerConnectionInProgress = false;
        },

        ///
        checkAIQServerHealthStart(state) {
            state.checkingAiqServerHealthInProgress = true;
            state.checkingAiqServerHealthError = null;
        },
        checkAIQServerHealthFailed(state, { payload: error }) {
            state.checkingAiqServerHealthInProgress = false;
            state.checkingAiqServerHealthError = error;
        },
        checkAIQServerHealthSuccess(state, { payload: aiqServerHealthStatus }) {
            state.checkingAiqServerHealthInProgress = false;
            state.aiqServerHealthStatus = aiqServerHealthStatus;
        },

        ///
        setActiveTab(state, { payload }) {
            state.activeTab = payload;
        },

        removeTransaction(state, { payload: transactionId }) {
            let transactions = state.transactions;
            state.transactions = transactions.filter((transaction) => transaction.id !== transactionId);

            state.activeTab = state.transactions.length ? state.transactions[0].tabId : divisionTabId;
        },
        setTransactions(state, { payload: newTransactions }) {
            state.transactions = newTransactions;
        },

        ///
        toggleIsReceivingTransactionsEnabled() {},
        setIsReceivingTransactionsEnabled(state, { payload }) {
            state.isReceivingTransactionsEnabled = payload;
        },

        ///videoWall
        setIsVideoWallEnabled(state, { payload }) {
            state.isVideoWallEnabled = payload;
        },

        setFetchingVideoWallCamerasInProgress(state, { payload: progress }) {
            state.fetchingVideoWallCamerasInProgress = progress;
        },
        removeVideoWallCameras(state) {
            state.videoWallCameras = {};
        },
        setVideoWallCameras(state, { payload: cameras }) {
            const transformedCameras = transformCameraConfigArray(cameras);

            const videoWallCameras = { level1: [], talkUrls: [] };

            const tabAudio = {
                isTabMuted: false,
                soundOnStreamId: '',
                availableAudioStreams: [],
            };

            let counter = 1;
            for (let cameraId of Object.keys(transformedCameras)) {
                for (let camConfig of transformedCameras[cameraId]) {
                    const camera = getCameraConfig(camConfig, `[L1]${counter}`, videoWallTabId, videoWallCameras, tabAudio);
                    camera.hidden = !camera.inSaturation;
                    videoWallCameras.level1.push(camera);
                }

                ++counter;
            }

            if (!tabAudio.soundOnStreamId) tabAudio.isTabMuted = true;

            state.tabsAudio[videoWallTabId] = tabAudio;
            state.videoWallCameras = videoWallCameras;
            state.activeTab === divisionTabId && (state.activeTab = videoWallTabId);
        },
        setCheckedStateToVideoCameras(state, { payload }) {
            const hiddenCamerasIds = payload.reduce((arr, camera) => {
                if (!camera.hidden) arr.push(camera.camId);
                return arr;
            }, []);

            state.videoWallCameras.level1 = state.videoWallCameras.level1.map((camConfig) => {
                camConfig.hidden = !hiddenCamerasIds.includes(camConfig.camId);
                return camConfig;
            });
        },
        getVideoWallCameras() {},
        toggleVideoWallEnabled() {},
        setChangingOperationalModeInProgress(state, { payload }) {
            state.changingOperationalModeInProgress = payload;
        },

        setUpdatingTransactionInProgress(state, { payload: { isUpdateInProgress, transactionId, initialState } }) {
            if (initialState) {
                state.updatingTransactionInProgress = {};
                return;
            }
            if (isUpdateInProgress) {
                state.updatingTransactionInProgress[transactionId] = true;
            } else {
                /* eslint-disable-next-line no-unused-vars */
                const { [transactionId]: toBeDeleted, ...rest } = state.updatingTransactionInProgress;
                state.updatingTransactionInProgress = rest;
            }
        },

        setLastClosedTransactionId(state, { payload: closedTransactionId = 0 }) {
            state.lastClosedTransactionId = closedTransactionId;
        },

        setHasReopenedTransaction(state, { payload: hasReopenedTransaction = false }) {
            state.hasReopenedTransaction = hasReopenedTransaction;
        },

        setReopenTransaction(state, { payload }) {
            state.reopenTransaction = payload;
        },

        handleNewTransaction(state, { payload: incomingTransaction }) {
            const allTransactions = state.transactions;
            const isReopened = incomingTransaction.reopened;
            const tabId = isReopened ? reopenedTransactionTabId : state.newTransactionTabId++;
            const newTransaction = {
                tabId: tabId,
                uuid: incomingTransaction.uuid,
                timestamp: {
                    received: incomingTransaction.timestamp,
                },
                locationId: incomingTransaction.location_id,
                locationName: incomingTransaction.location_name,
                gateId: incomingTransaction.gate_id,
                gateName: incomingTransaction.gate_name,
                gateDirection: incomingTransaction.gate_direction,
                deskId: incomingTransaction.desk_id,
                id: incomingTransaction.truck_transaction_id,
                version: incomingTransaction.version,
                reopened: incomingTransaction.reopened,
                camsConfig: { level1: [], level2: [], talkUrls: [] },
            };

            const tabAudio = {
                isTabMuted: false,
                soundOnStreamId: '',
                availableAudioStreams: [],
            };
            const L1Cameras = transformCameraConfigArray(incomingTransaction.cameras.level1);

            const L2Cameras = transformCameraConfigArray(incomingTransaction.cameras.level2);

            let counter = 1;

            for (let cameraId of Object.keys(L1Cameras)) {
                for (let camConfig of L1Cameras[cameraId])
                    newTransaction.camsConfig.level1.push(getCameraConfig(camConfig, `[L1]${counter}`, tabId, newTransaction.camsConfig, tabAudio));
                ++counter;
            }

            counter = 1;
            for (let cameraId of Object.keys(L2Cameras)) {
                for (let camConfig of L2Cameras[cameraId])
                    newTransaction.camsConfig.level2.push(getCameraConfig(camConfig, `[L2]${counter}`, tabId, newTransaction.camsConfig, tabAudio));
                ++counter;
            }

            if (!tabAudio.soundOnStreamId) tabAudio.isTabMuted = true;

            state.tabsAudio[tabId] = tabAudio;
            !isReopened && state.transactions.push(newTransaction);
            if (isReopened) {
                state.transactions.unshift(newTransaction);
                state.hasReopenedTransaction = true;
            }
            state.activeTab = allTransactions.length === 1 || isReopened ? newTransaction.tabId : state.activeTab;
        },

        updateTransactionVersion(state, { payload: { transactionId, version } }) {
            state.transactions.forEach((transaction) => {
                if (transaction.id === transactionId) transaction.version = version;
            });
        },

        closeTransactionPanel(state, { payload: { closeAllPanels = false, tabId } }) {
            if (closeAllPanels) {
                state.newTransactionTabId = 1;
                state.videosFailedToLoad = {};
                state.videosFreezeDetected = {};
                state.tabsAudio = {};
                state.lastClosedTransactionId = 0;
            } else {
                let videosFailedToLoad = state.videosFailedToLoad;
                let videosFreezeDetected = state.videosFreezeDetected;
                let tabsAudio = state.tabsAudio;
                /* eslint-disable no-unused-vars */
                const { [tabId]: videosToBeRemoved, ...newVideosFailedToLoad } = videosFailedToLoad;
                const { [tabId]: videostoBeRemoved, ...newVideosFreezeDetected } = videosFreezeDetected;
                const { [tabId]: toBeDeleted, ...newTabsAudio } = tabsAudio;
                /* eslint-enable no-unused-vars */
                state.videosFailedToLoad = newVideosFailedToLoad;
                state.videosFreezeDetected = newVideosFreezeDetected;
                state.tabsAudio = newTabsAudio;
            }
        },

        setRequestFullScreenForTabId(state, { payload: tabId }) {
            state.requestFullScreenForTabId = tabId;
        },

        setTabAudioMuted(state, { payload: { tabId, muted } }) {
            state.tabsAudio[tabId] && (state.tabsAudio[tabId].isTabMuted = muted);
        },

        setTabAudioSoundOnStreamId(state, { payload: { tabId, soundOnStreamId, muteTab } }) {
            if (muteTab) {
                state.tabsAudio[tabId].isTabMuted = true;
                return;
            }
            const currentSoundOnStreamId = state.tabsAudio[tabId].soundOnStreamId;
            const isTabMuted = state.tabsAudio[tabId].isTabMuted;

            if (currentSoundOnStreamId !== soundOnStreamId) {
                state.tabsAudio[tabId].soundOnStreamId = soundOnStreamId;
                state.tabsAudio[tabId].isTabMuted = false;
            } else state.tabsAudio[tabId].isTabMuted = !isTabMuted;
        },

        setActiveTalkUrl(state, { payload: { activeTalkUrl, sendSignal = true } }) {
            state.activeTalkUrl = activeTalkUrl;
            if (!activeTalkUrl && state.talkSessionPaused) state.talkSessionPaused = false;
            state.talkSessionStarting = !!activeTalkUrl && !!sendSignal;
        },

        setActiveDemoTalkUrl(state, { payload: { talkUrl } }) {
            state.activeDemoTalkUrl = talkUrl;
        },

        setTalkSessionStarting(state, { payload }) {
            state.talkSessionStarting = payload;
        },
        setTalkSessionPaused(state, { payload }) {
            state.talkSessionPaused = payload;
        },

        deskAssignedMessageReceived() {},

        setCameraConfig(state, { payload: { transactionIdsArr, camConfig, activeOnVideoWall } }) {
            state.transactions.forEach((transaction) => {
                if (transactionIdsArr.includes(transaction.id)) {
                    const transactionCams = transaction.camsConfig;
                    [transactionCams.level1, transactionCams.level2].forEach((level) => replaceCameraStreamConfig(level, camConfig));
                }
            });
            if (activeOnVideoWall) {
                replaceCameraStreamConfig(state.videoWallCameras.level1, camConfig);
            }
        },
        removedTransactionDetection(state, { payload }) {
            state.videoWallAlisaTransactionDetection = state.videoWallAlisaTransactionDetection.filter(
                (el) => el.gate_camera_id !== payload.gate_camera_id
            );
            /* eslint-disable-next-line no-unused-vars */
            const { [payload.gate_camera_id]: toBeDeleted, ...rest } = state.transactionDetectionAgentInformation;
            state.transactionDetectionAgentInformation = rest;
        },
        setTransactionDetectionList(state, { payload }) {
            state.videoWallAlisaTransactionDetection = [...state.videoWallAlisaTransactionDetection, payload];
        },
        setTransactionDetectionAgentInformation(state, { payload }) {
            const camId = payload.gate_camera_id;
            const copyiedState = { ...state.transactionDetectionAgentInformation };
            copyiedState[camId] = payload.account_username;
            state.transactionDetectionAgentInformation = copyiedState;
        },
        getNameForTransaction(state, { payload }) {
            const transaction = [...state.videoWallAlisaTransactionDetection].map((el) => {
                if (el.camera_id === payload.camera_id) {
                    el.accountUsername = payload.account_username;
                }
                return el;
            });

            state.videoWallAlisaTransactionDetection = transaction;
        },

        setWindowId(state, { payload: { id: windowId, uid } }) {
            if (state.sharedWorker) state.sharedWorker.windowId = windowId;
            else state.sharedWorker = { windowId, uid };
        },
        setWindowCount(state, { payload: windowCount }) {
            if (state.sharedWorker) state.sharedWorker.windowCount = windowCount;
            else state.sharedWorker = { windowCount };
        },
        registerSharedWorker() {},
        setReportIssuesModalState(state, { payload: modalState }) {
            state.reportIssuesModalState = modalState;
        },

        //report issues
        reportIssues() {},
        setSelectedCameraIssues(state, { payload: issues }) {
            state.selectedCameraIssues = issues;
        },
        setSelectedIssueUrl(state, { payload: issues }) {
            state.selectedIssueUrl = issues;
        },
        setIssueTitle(state, { payload: title }) {
            state.issueTitle = title;
        },
        setIssueDataLoggs(state, { payload }) {
            state.issueDataLoggs = payload;
        },
    },
});

export const transactionsReducer = transactionsSlice.reducer;
export const transactionActions = transactionsSlice.actions;

function getCameraConfig(camera, id, tabId, cemeraConfigsObj, tabAudio) {
    const type = camera.camera_type;
    if (audioCameraTypes.includes(type)) {
        // if (!tabAudio.soundOnStreamId) tabAudio.soundOnStreamId = kurentoManager.getVideoElementId(tabId, id);
        const videoOutputId = kurentoManager.getVideoElementId(tabId, id);
        !tabAudio.availableAudioStreams.includes(videoOutputId) && tabAudio.availableAudioStreams.push(kurentoManager.getVideoElementId(tabId, id));
    }
    if (camera.talk_url) cemeraConfigsObj.talkUrls.push(camera.talk_url);

    ///transformCameraConfiguration is pure function and it is allowed to be used inside reducer
    return transformCameraConfiguration(camera, id);
}

function transformCameraConfigArray(cameras) {
    return cameras.reduce((transformed, camera) => {
        const generatedName = camera.generated_name;
        if (!transformed[generatedName]) transformed[generatedName] = [camera];
        else transformed[generatedName].push(camera);
        return transformed;
    }, {});
}

const replaceCameraStreamConfig = function (configArr, camConfig) {
    configArr.forEach(
        (camera, i) =>
            camera.camId === camConfig.camId &&
            (camConfig.status === 'INACTIVE' ||
                (camera.videoData.streamType === camConfig.videoData.streamType &&
                    camera.videoData.playerEndpointId !== camConfig.videoData.playerEndpointId)) &&
            (configArr[i] = { ...camera, ...camConfig })
    );
};
