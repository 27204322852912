import styles from './CameraStreamFooter.module.css';
import SettingsIcon from '@mui/icons-material/Settings';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import SyncIcon from '@mui/icons-material/Sync';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import { kurentoManager } from 'services/Kurento/KurentoManager';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { transactionActions } from 'store/transactionsSlice/transactionsSlice';
import BugIcon from '../cameraStreamBugButton/CameraStreamBugButton';
import TakeSnapshot from 'components/UI/TakeSnapshot/TakeSnapshot';
import { formControlActions } from 'store/formControlSlice/formControlSlice';
import { IMAGE_CATEGORIES } from '../../images/constants';
import TwoWayAudio from './TwoWayAudio/TwoWayAudio';
import { Tooltip } from '@mui/material';

function CameraStreamFooter({
    hovered,
    isVideoLoading,
    videoFailedToLoad,
    camConfig,
    refreshVideoStreamHandler,
    hardRefreshVideoStreamHandler,
    level1,
    openDialogHandler,
    tabAudioConfig,
    tabId,
    expandedCamId,
    onToggleAudio,
    enableDemoAudioFile,
    isFullScreenRequested,
    footerMode,
    cameraIssues,
    transactionId,
    camInfo,
    videoWallCameras,
}) {
    const dispatch = useDispatch();

    const hasCameraIssues = cameraIssues?.length > 0;

    const isMtxDirect = camConfig.mtxDirect;
    const { camId } = camConfig;

    const { isTabMuted, soundOnStreamId, availableAudioStreams } = tabAudioConfig;

    const camVideoId = kurentoManager.getVideoElementId(tabId, camConfig.id);
    const audioEnabled = !isTabMuted && soundOnStreamId === camVideoId;
    const isAudioAvailable = availableAudioStreams.includes(camVideoId);

    let soundIndicatorStyles;
    if (!audioEnabled) soundIndicatorStyles = { color: '#464646fe' };
    if (!isAudioAvailable) soundIndicatorStyles = { display: 'none' };

    const footerContainerStyles = `${styles['tc-cs-footer']}${isMtxDirect ? ` ${styles['tc-cs-footer--mtx']}` : ''} ${
        styles['tc-cs-footer--absolute']
    }${!isVideoLoading && !videoFailedToLoad ? ` ${styles['tc-cs-footer--box-shadow']}` : ''}${
        (expandedCamId && expandedCamId !== camConfig.id && !level1) || tabId === 'wall' ? ` ${styles['tc-cs-footer--L2-shrinked']}` : ''
    } ${(hovered || !videoWallCameras) && styles['hasOpacity']}`;

    const title = camConfig.name ?? '';
    const gateName = camConfig.gateName ?? '';
    const direction = camConfig.direction ?? '';
    const level = camConfig?.level ?? '';

    const clickHandlerCameraBugIcon = (state) => {
        dispatch(transactionActions.setReportIssuesModalState(state));
        dispatch(transactionActions.setSelectedCameraIssues(cameraIssues));
        if (transactionId === 'videoWall') {
            dispatch(transactionActions.setSelectedIssueUrl('/transactions/trucks/detection/issue'));
        } else {
            dispatch(transactionActions.setSelectedIssueUrl(`/transactions/trucks/${transactionId}/issue`));
        }
        dispatch(transactionActions.setIssueTitle(cameraIssues[0].camera.name));
        dispatch(transactionActions.setIssueDataLoggs(camInfo));
    };

    const isCameraActive = camConfig?.status !== 'INACTIVE';
    const isTransactionPage = typeof transactionId === 'number';

    const [snapshots, setSnaphots] = useState([]);

    function onTakeSnapshot() {
        const id = Date.now();
        const onSnapshot = (_, base64) => {
            const snapshot = base64.split(',').at(1);
            dispatch(formControlActions.saveCameraStreamSnapshot({ transactionId, snapshot, id, imageCategory: IMAGE_CATEGORIES.CAMERA_SNAPSHOT }));
        };
        const snapshot = <TakeSnapshot key={id} id={id} videoElementId={camInfo?.camVideoId} onSnapshot={onSnapshot} />;
        setSnaphots(() => [snapshot]);
    }

    return (
        <>
            <div className={footerContainerStyles}>
                <p>{title}</p>
                {footerMode && gateName && getFooterInfo(footerMode, gateName, direction, level)}
                <div className={styles['tc-cs-footer-actions']}>
                    {!footerMode && hasCameraIssues && isCameraActive && <BugIcon clickHandler={clickHandlerCameraBugIcon} />}
                    {isCameraActive && isTransactionPage && (
                        <Tooltip title="Take snapshot">
                            <CameraAltIcon fontSize="small" style={{ color: 'white', cursor: 'pointer' }} onClick={onTakeSnapshot} />
                        </Tooltip>
                    )}
                    {audioEnabled && isCameraActive && (
                        <Tooltip title="Turn off sound">
                            <VolumeUpIcon fontSize="small" style={{ ...soundIndicatorStyles, cursor: 'pointer' }} onClick={onToggleAudio} />
                        </Tooltip>
                    )}
                    {!audioEnabled && isCameraActive && (
                        <Tooltip title="Turn on sound">
                            <VolumeOffIcon fontSize="small" style={{ ...soundIndicatorStyles, cursor: 'pointer' }} onClick={onToggleAudio} />
                        </Tooltip>
                    )}

                    <TwoWayAudio
                        onToggleAudio={onToggleAudio}
                        enableDemoAudioFile={enableDemoAudioFile}
                        camVideoId={camVideoId}
                        camId={camId}
                        camConfig={camConfig}
                    />

                    {!isMtxDirect && isCameraActive && (
                        <Tooltip title="Hard refresh stream">
                            <SyncProblemIcon
                                fontSize="small"
                                style={{
                                    color: 'white',
                                    cursor: 'pointer',
                                }}
                                onClick={hardRefreshVideoStreamHandler}
                            />
                        </Tooltip>
                    )}

                    {isCameraActive && (
                        <Tooltip title={isMtxDirect ? 'Refresh stream' : 'Soft refresh stream'}>
                            <SyncIcon
                                fontSize="small"
                                style={{
                                    color: 'white',
                                    cursor: 'pointer',
                                }}
                                onClick={refreshVideoStreamHandler}
                                disabled={true}
                            />
                        </Tooltip>
                    )}
                    {!isFullScreenRequested && (
                        <Tooltip title="Camera info and debug">
                            <SettingsIcon fontSize="small" style={{ color: 'white', cursor: 'pointer' }} onClick={openDialogHandler} />
                        </Tooltip>
                    )}
                </div>
            </div>
            {snapshots}
        </>
    );
}

export default CameraStreamFooter;

function getFooterInfo(footerDebugMode, gateName, direction, level) {
    const common = (
        <>
            <p>|</p>
            <p>{gateName}</p>
            <p>|</p>
            <p>{direction}</p>
        </>
    );

    if (footerDebugMode === 'videoWall') return;
    return (
        <>
            {common}
            <p>|</p>
            <p>{level}</p>
        </>
    );
}
