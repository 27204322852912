import { useRef } from 'react';
import styles from './Note.module.css';
import { PERIOD_ACCESS_TYPES } from 'utils/constants';
import { renderLimitedTimeAccess, renderPermanentAccess } from './NoteUtils';
import { Divider } from '@mui/material';

function Note({ mode, hovered, name, note, color, accessPeriod }) {
    const isHoverMode = mode === 'hover';
    const { accessType = '', startTime = '', endTime = '', weekdays = [], periodType = '' } = accessPeriod;
    const isVisible = (isHoverMode && hovered) || !isHoverMode;
    const noteRef = useRef();

    const alertNameString = `${name}${note ? ': ' : ''}`;

    //styles
    const containerStyles = `${styles.note}${!isVisible ? ` ${styles['hidden']}` : ''}${isHoverMode ? ` ${styles['note--absolute']}` : ''}`;
    const inlineStyle = {
        backgroundColor: color,
        padding: '0.5rem',
        width: noteRef?.current?.closest('div[class*=AIQInput_input]')?.getBoundingClientRect()?.width || '340px',
    };
    const isBanned = name === 'Banned';
    const isRenderLimitedTimeAccess = !isBanned && periodType !== 'NO_LIMIT';

    return (
        <div className={containerStyles} {...(isHoverMode && { style: inlineStyle })} ref={noteRef}>
            <p>
                <span>{alertNameString}</span> {note}
            </p>
            <Divider />

            {accessType && (
                <p>
                    <span>Period Access:</span> {PERIOD_ACCESS_TYPES[accessType]} {renderPermanentAccess(startTime, endTime)}
                </p>
            )}
            {isRenderLimitedTimeAccess && (
                <>
                    <span> Limited-time Access:</span>
                    <p className={styles['limited']}>{renderLimitedTimeAccess(weekdays || [])}</p>
                </>
            )}
        </div>
    );
}

export default Note;
