import { USER_ROLES } from 'utils/constants';

export const routes = [
    {
        path: '/',
        text: 'Homepage',
    },
    {
        path: '/transaction',
        text: 'Transaction',
    },
    {
        path: '/vin-check',
        text: 'Vin Check',
    },
    {
        path: '/all-transactions',
        text: 'All Transactions',
    },

    {
        path: '/all-announcements',
        text: 'All Announcements',
    },

    {
        text: 'Admin',
        items: [
            {
                path: '/desk-assign',
                text: 'Saturation',
            },
            {
                allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.TEAM_LEADER],
                path: '/camera-configuration',
                text: 'Camera Configuration',
            },
            {
                allowedRoles: [USER_ROLES.ADMIN],
                path: '/desk-configuration',
                text: 'Desk Configuration',
            },
            {
                path: '/stream-test',
                text: 'Stream Test',
            },
            {
                path: 'issue-tracker',
                text: 'Issue Tracker',
            },
            {
                path: 'track-streaming',
                text: 'Track Streaming',
            },
        ],
    },
];
